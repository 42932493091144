/* common functions */
export function el(selector) {
  return document.querySelector(selector);
}

export function all(selector) {
  return document.querySelectorAll(selector);
}

export function on(selector, event, action) {
  all(selector).forEach((e) => e.addEventListener(event, action));
}

export function someCookie(name) {
  const cook = document.cookie.split('; ').find((cookie) => cookie && cookie.startsWith(`${name}=`));
  return cook ? cook.split('=')[1] : false;
}

export default {
  el,
  all,
  on,
  someCookie,
};
