/* eslint-disable no-unused-vars */
import '@/scss/style.scss';

// utilities
import '@/utils/functions';

// components
import '@/js/components/dropdown';
import '@/js/components/popover';
import '@/js/components/tooltip';
import '@/js/components/modal';
import '@/js/components/tab';

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
