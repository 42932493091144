/**
 * Dropdown
 * @requires https://getbootstrap.com
 */

import * as Dropdown from 'bootstrap/js/dist/dropdown';

const dropdown = (() => {
  document.querySelectorAll('[data-bs-toggle="dropdown"]').forEach((dropdownTriggerEl) => new Dropdown(dropdownTriggerEl));
  return null;
})();

export default dropdown;
