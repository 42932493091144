/* eslint-disable no-new */

/**
 * Tooltip
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
 */

import * as Tooltip from 'bootstrap/js/dist/tooltip';
// import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm';

const tooltip = (() => {
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  return null;
})();

export default tooltip;
