/**
 * Modal
 * @requires https://getbootstrap.com
 */

import * as Modal from 'bootstrap/js/dist/modal';

const modal = (() => {
  document.querySelectorAll('[data-bs-toggle="modal"]').forEach((modalTriggerEl) => new Modal(modalTriggerEl));
  return null;
})();

export default modal;
