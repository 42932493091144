/**
 * Modal
 * @requires https://getbootstrap.com
 */

import * as Tab from 'bootstrap/js/dist/tab';

const tab = (() => {
  document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tabTriggerEl) => new Tab(tabTriggerEl));
  return null;
})();

export default tab;
