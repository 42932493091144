/* eslint-disable no-new */

/**
 * Popover
 * @requires https://getbootstrap.com
 * @requires https://popper.js.org/
 */

import * as Popover from 'bootstrap/js/dist/popover';
// import { Popover } from 'bootstrap/dist/js/bootstrap.esm';

const popover = (() => {
  document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popoverTriggerEl) => new Popover(popoverTriggerEl));
  return null;
})();

export default popover;
